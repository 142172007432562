<template>
  <div class="inner-acc">
    <div class="wrapper rowflex">
      <sidebar-account></sidebar-account>
      <article class="main">
        <div class="inner-reqprop">
          <div class="hentry">
            <h2>Looking for a specific property? Tell us!</h2>
            <div class="format-text">
              <p>
                We understand that you may have specific needs and preferences
                in search of the right property, and we will be happy to find
                them for you. Let us know below! Should we find a property that
                suits your requirements, we will let you know. An extra benefit:
                we may even prioritize to alert you of a suitable property
                before it gets publicly listed on our platform!
              </p>
            </div>
            <!-- end of format text -->
          </div>
          <!-- end of hentry -->

          <form @submit.prevent="submitRequest">
            <div class="gap">
              <div class="fgroup">
                <label>City *</label>
                <input type="text" v-model="city" />
                <span class="error" v-if="error.city">city is required</span>
              </div>
              <!-- end of fgroup -->
              <div class="fgroup">
                <label>Area</label>
                <input type="text" v-model="area" />
              </div>
              <!-- end of fgroup -->
            </div>
            <!-- end of gap -->
            <div class="gap">
              <div class="fgroup">
                <label>Land Size Range</label>
                <div class="inlined">
                  <div class="field-price toright">
                    <b>Sqm</b>
                    <input
                      type="text"
                      v-model="minLandSize"
                      placeholder="Minimum"
                      @keypress="isNumber($event)"
                    />
                  </div>
                  <span>-</span>
                  <div class="field-price toright">
                    <b>Sqm</b>
                    <input
                      type="text"
                      v-model="maxLandSize"
                      placeholder="Maximum"
                      @keypress="isNumber($event)"
                    />
                  </div>
                </div>
              </div>
              <!-- end of fgroup -->
              <div class="fgroup">
                <label>Building Size Range</label>
                <div class="inlined">
                  <div class="field-price toright">
                    <b>Sqm</b>
                    <input
                      type="text"
                      v-model="minBuildingSize"
                      placeholder="Minimum"
                      @keypress="isNumber($event)"
                    />
                  </div>
                  <span>-</span>
                  <div class="field-price toright">
                    <b>Sqm</b>
                    <input
                      type="text"
                      v-model="maxBuildingSize"
                      placeholder="Maximum"
                      @keypress="isNumber($event)"
                    />
                  </div>
                </div>
              </div>
              <!-- end of fgroup -->
            </div>
            <!-- end of gap -->
            <div class="fgroup">
              <label>Price Range</label>
              <div class="field-radio">
                <label
                  ><input
                    type="radio"
                    name="price_range"
                    :value="`price`"
                    v-model="priceRange"
                  />
                  <span>Property Price</span></label
                >
                <label
                  ><input
                    type="radio"
                    name="price_range"
                    :value="`rent`"
                    v-model="priceRange"
                  />
                  <span>Rent per Month</span></label
                >
              </div>
              <!-- end of field radio -->
              <div class="inlined">
                <div class="field-price">
                  <b>Rp</b>
                  <input
                    type="text"
                    v-model="price.minPrice"
                    @keyup="formatRupiah('minPrice')"
                    placeholder="Minimum Price"
                  />
                </div>
                <span>-</span>
                <div class="field-price">
                  <b>Rp</b>
                  <input
                    type="text"
                    v-model="price.maxPrice"
                    @keyup="formatRupiah('maxPrice')"
                    placeholder="Maximum Price"
                  />
                </div>
              </div>
              <!-- end of field text -->
            </div>
            <div class="fgroup">
              <label>Property Type *</label>
              <div class="field-check quatro">
                <label
                  ><input
                    type="checkbox"
                    name="property"
                    :value="1"
                    v-model="propertyType"
                  /><span>Landed House</span></label
                >
                <label
                  ><input
                    type="checkbox"
                    name="property"
                    :value="6"
                    v-model="propertyType"
                  /><span>Ruko (Shophouse)</span></label
                >
                <label
                  ><input
                    type="checkbox"
                    name="property"
                    :value="3"
                    v-model="propertyType"
                  /><span>Land</span></label
                >
                <label
                  ><input
                    type="checkbox"
                    name="property"
                    :value="5"
                    v-model="propertyType"
                  /><span>Warehouse</span></label
                >
                <label
                  ><input
                    type="checkbox"
                    name="property"
                    :value="2"
                    v-model="propertyType"
                  /><span>Apartment</span></label
                >
                <label
                  ><input
                    type="checkbox"
                    name="property"
                    :value="4"
                    v-model="propertyType"
                  /><span>Office</span></label
                >
                <label
                  ><input
                    type="checkbox"
                    name="property"
                    :value="8"
                    v-model="propertyType"
                  /><span>Soho</span></label
                >
                <label>
                  <input
                    type="checkbox"
                    name="property"
                    :value="0"
                    v-model="propertyType"
                  />
                  <span>Other: <input type="text" id="other"/></span>
                </label>
              </div>
              <span class="error" v-if="error.property">
                property type is required
              </span>
            </div>
            <div class="fgroup">
              <label>Transaction Type *</label>
              <div class="field-check">
                <label
                  ><input
                    type="checkbox"
                    name="transaction"
                    :value="1"
                    v-model="transactionType"
                  /><span>Rent</span></label
                >
                <label
                  ><input
                    type="checkbox"
                    name="transaction"
                    :value="2"
                    v-model="transactionType"
                  /><span>Rent-to-Own</span></label
                >
                <label
                  ><input
                    type="checkbox"
                    name="transaction"
                    :value="3"
                    v-model="transactionType"
                  /><span>Buy</span></label
                >
              </div>
              <span class="error" v-if="error.transaction">
                transaction is required
              </span>
            </div>
            <div class="gap">
              <div class="fgroup">
                <label>Number of Bedrooms</label>
                <div class="field-check">
                  <label
                    ><input
                      type="checkbox"
                      name="bedroom"
                      value="Studio"
                      v-model="numBedroom"
                    /><span>Studio</span></label
                  >
                  <label
                    ><input
                      type="checkbox"
                      name="bedroom"
                      value="1"
                      v-model="numBedroom"
                    /><span>1</span></label
                  >
                  <label
                    ><input
                      type="checkbox"
                      name="bedroom"
                      value="2"
                      v-model="numBedroom"
                    /><span>2</span></label
                  >
                  <label
                    ><input
                      type="checkbox"
                      name="bedroom"
                      value="3"
                      v-model="numBedroom"
                    /><span>3</span></label
                  >
                  <label
                    ><input
                      type="checkbox"
                      name="bedroom"
                      value="4+"
                      v-model="numBedroom"
                    /><span>4+</span></label
                  >
                </div>
              </div>
              <div class="fgroup">
                <label>Number of Bathrooms</label>
                <div class="field-check">
                  <label
                    ><input
                      type="checkbox"
                      name="bathroom"
                      value="1"
                      v-model="numBathroom"
                    /><span>1</span></label
                  >
                  <label
                    ><input
                      type="checkbox"
                      name="bathroom"
                      value="2"
                      v-model="numBathroom"
                    /><span>2</span></label
                  >
                  <label
                    ><input
                      type="checkbox"
                      name="bathroom"
                      value="3"
                      v-model="numBathroom"
                    /><span>3</span></label
                  >
                  <label
                    ><input
                      type="checkbox"
                      name="bathroom"
                      value="4+"
                      v-model="numBathroom"
                    /><span>4+</span></label
                  >
                </div>
              </div>
            </div>
            <!-- end of gap -->
            <div class="gap">
              <div class="fgroup">
                <label>Number of Car Lots</label>
                <div class="field-check">
                  <label
                    ><input
                      type="checkbox"
                      name="carlot"
                      value="1"
                      v-model="numCarLot"
                    /><span>1</span></label
                  >
                  <label
                    ><input
                      type="checkbox"
                      name="carlot"
                      value="2"
                      v-model="numCarLot"
                    /><span>2</span></label
                  >
                  <label
                    ><input
                      type="checkbox"
                      name="carlot"
                      value="3"
                      v-model="numCarLot"
                    /><span>3</span></label
                  >
                  <label
                    ><input
                      type="checkbox"
                      name="carlot"
                      value="4+"
                      v-model="numCarLot"
                    /><span>4+</span></label
                  >
                </div>
              </div>
              <div class="fgroup">
                <label>Status</label>
                <div class="field-check">
                  <label
                    ><input
                      type="checkbox"
                      name="status"
                      value="Primary"
                      v-model="status"
                    /><span>Primary</span></label
                  >
                  <label
                    ><input
                      type="checkbox"
                      name="status"
                      value="Secondary"
                      v-model="status"
                    /><span>Secondary</span></label
                  >
                </div>
              </div>
            </div>
            <!-- end of gap -->
            <div class="fgroup">
              <label>Other Requests</label>
              <textarea v-model="notes"></textarea>
              <em
                >Information indicated * are mandatory, all others are
                optional</em
              >
            </div>
            <div class="action">
              <button type="submit" class="button">Request Property</button>
            </div>
          </form>
        </div>
        <!-- end of inner reqprop-->
      </article>
    </div>
    <!-- end of wrapper -->
  </div>
  <!-- end of inner mort -->
</template>

<script>
import SidebarAccount from "./SidebarAccount.vue";

export default {
  name: "AccountRequestProperty",
  metaInfo: {
    title: "Account Request Property"
  },
  components: {
    SidebarAccount
  },
  data() {
    return {
      city: "",
      area: "",
      minLandSize: "",
      maxLandSize: "",
      minBuildingSize: "",
      maxBuildingSize: "",
      transactionType: [],
      price: {
        minPrice: "",
        maxPrice: ""
      },
      priceRange: "",
      propertyType: [],
      numBedroom: [],
      numBathroom: [],
      numCarLot: [],
      status: [],
      notes: "",
      error: {
        city: false,
        property: false,
        transaction: false
      }
    };
  },
  methods: {
    formatRupiah(priceType) {
      const angka = this.convertToAngka(this.price[priceType]);
      const rupiah = this.convertToRupiah(angka, false);
      this.price[priceType] = rupiah;
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    submitRequest() {
      this.error.city = this.city === "" ? true : false;
      this.error.property = this.propertyType.length === 0 ? true : false;
      this.error.transaction = this.transactionType.length === 0 ? true : false;
      if (this.error.property === false) {
        if (
          this.propertyType.includes(0) &&
          document.getElementById("other").value === ""
        ) {
          this.error.property = true;
        }
      }
      if (
        this.error.city === true ||
        this.error.property === true ||
        this.error.transaction === true
      ) {
        return false;
      }
      if (this.propertyType.includes(0)) {
        this.propertyType.push(document.getElementById("other").value);
      }
      this.$axios
        .post(`/request-properties`, {
          city: this.city,
          area: this.area,
          minLandSize: this.minLandSize,
          maxLandSize: this.maxLandSize,
          minBuldingSize: this.minBuildingSize,
          maxBuildingSize: this.maxBuildingSize,
          transactionType: this.transactionType,
          minPrice: this.convertToAngka(this.price.minPrice),
          maxPrice: this.convertToAngka(this.price.maxPrice),
          propertyType: this.propertyType,
          numBedroom: this.numBedroom,
          numBathroom: this.numBathroom,
          numCarLot: this.numCarLot,
          status: this.status,
          notes: this.notes,
          statusPrice: this.priceRange
        })
        .then(response => {
          this.$swal({
            icon: "success",
            text: response.data.message
          });
          this.city = "";
          this.area = "";
          this.minLandSize = "";
          this.maxLandSize = "";
          this.minBuildingSize = "";
          this.maxBuildingSize = "";
          this.transactionType = [];
          this.price = {
            minPrice: "",
            maxPrice: ""
          };
          this.propertyType = [];
          this.numBedroom = [];
          this.numBathroom = [];
          this.numCarLot = [];
          this.status = [];
          this.notes = "";
          document.getElementById("other").value = "";
        })
        .catch(error => {
          this.$swal({
            icon: "error",
            text: error.response.data.message
          });
        });
    }
  }
};
</script>

<style scoped>
.field-price.toright b {
  max-height: 36px !important;
}
.field-price b {
  max-height: 36px !important;
}
.error {
  font-size: 11px !important;
  color: red !important;
}
</style>
